import { DialogContentContainer, Flex, List, ListTitle, } from "monday-ui-react-core";
import LineItem from "./LineItem";

const ItemList = (props) => {

  if (!props) return (<h1>En cours de chargement</h1>)

  //Tri la liste selon le nom des groupes
  props.items.sort((a, b) => a.group.title < b.group.title ? -1 : 1)

  const copyToClipboard = async (event) => {
    let text = '';
    console.log('CLIEkED :', event)
    if ('clipboard' in navigator) {
      const groupName = event.target.id;
      return await navigator.clipboard.writeText(groupName);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  let groupPrinted = [];
  return (<Flex align={Flex.align.START}
    gap={Flex.gaps.SMALL} style={{
      width: "100%"
    }} direction={Flex.directions.ROW}>
    {props.items.map(item => {
      console.log('item en cours:', item)
      return groupPrinted.includes(item.group.title) ? '' : (<div key={'card-' + item.group.title} style={{
        cursor: 'pointer',
      }} onClick={copyToClipboard} >
        <DialogContentContainer size={DialogContentContainer.sizes.SMALL} style={{
          height: "159px",
          width: "200px"
        }}>
          <List style={{
            height: "100%",
            width: "100%"
          }}>
            <ListTitle key={item.id}>{item.group.title}</ListTitle>
            <LineItem items={props.items} actualGroup={item.group.title}></LineItem>
            {groupPrinted.push(item.group.title)}
          </List>
        </DialogContentContainer></div>)
    })}
  </Flex>)
}

export default ItemList;