import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();
monday.setApiVersion('2024-01')

export const getItemByIds = async (ids) => {
    const query = `query($ids: [ID!]) {
            items (ids: $ids) { 
                    id, name, 
                    group { 
                        title
                    }
                }
            }`;

    const response = await monday.api(query, { variables: { ids } })
    return response.data;
}