import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import { getItemByIds } from "./services/item-service.js";
import ItemList from "./components/ItemList.js";
import { Loader } from "monday-ui-react-core";

const monday = mondaySdk();

const App = () => {
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    monday.get("context").then(response => {

      const selectedIds = response.data.selectedPulsesIds;
      let itemsApi = []

      getItemByIds(selectedIds).then(res => {
        itemsApi.push(...res.items);
        setItems(itemsApi)
        setLoading(false)
      })
    }).catch((err) => console.error(err));
  }

  useEffect(() => {
    // Notice this method notifies the monday platform that user gains a first value in an app.
    // Read more about it here: https://developer.monday.com/apps/docs/mondayexecute#value-created-for-user/
    monday.execute("valueCreatedForUser");

    fetchData();
  }, []);

  return (
    <div className="App">
      {loading ? (<Loader size={80} color="var(--primary-color)" />) : (<ItemList items={items} ></ItemList>)}
    </div>
  );
};

export default App;
