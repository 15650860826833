import React from 'react';
import { ListItem } from "monday-ui-react-core";

const LineItem = (props) => {

    const items = props.items;
    return items.filter(e=>e.group.title===props.actualGroup).map((item) => {
        return <ListItem key={item.id}>{item.name}</ListItem>
    })
}

export default LineItem;